import request from '_utils/request'

const get_config_by_key = (key) => {
  return request({
    url: `/api/system/config/getValue/${key}`,
    method: 'GET'
  })
}

export {
  get_config_by_key
}
