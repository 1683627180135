<template>
  <div id="loan-info">
    <div class="count-down-cover" v-show="countDownCoverIsShow">
      <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image.gif"
        alt=""
      />
    </div>
    <MyLoading :show="loadingIsShow" />
    <van-popup
      v-model="showCityPickerPopup"
      position="bottom"
      style="border-top: 26px solid rgba(0, 0, 0, 0); background: transparent"
    >
      <van-picker
        title="选择城市"
        :columns="cityData"
        @cancel="showCityPickerPopup = false"
        @confirm="selectCity"
        value-key="name"
        :show-toolbar="true"
      />
    </van-popup>
    <van-popup
      v-model="protocolPopupIsShow"
      position="bottom"
      :close-on-click-overlay="false"
      round
      @close="
        () => {
          bigAgreementName = ['个人信息授权书'];
          bigAgreementFile = [];
        }
      "
    >
      <div class="protocol-popup-container">
        <div
          class="protocol-content-container"
          ref="protocol-content-container"
        >
          <van-tabs v-model="protocolsTitleIndex" animated :ellipsis="false">
            <van-tab
              v-for="(item, index) in bigAgreementName"
              :title="item"
              :key="index"
            >
              <div
                v-if="item === '个人信息授权书' && protocolPopupIsShow"
                v-html="protocolContentHtml"
                :class="{
                  'protocol-content': true,
                  'protocol-content-no-scroll': stopSubmit,
                }"
                ref="content"
              ></div>
              <iframe
                v-else
                :src="
                  bigAgreementName.length === 2
                    ? bigAgreementFile[0]
                    : bigAgreementFile[index]
                "
                frameborder="0"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div
          class="protocol-do-agree-button"
          v-show="!okButtonIsShow"
          v-throttle="2000"
          @click="doSubmit"
        >
          我同意并申请
        </div>
        <div
          class="protocol-refuse-button"
          v-show="!okButtonIsShow"
          @click="refuseProtocol"
        >
          我不同意
        </div>
        <div
          class="protocol-do-agree-button"
          v-show="okButtonIsShow"
          @click="protocolPopupIsShow = false"
        >
          好的
        </div>
      </div>
    </van-popup>
    <van-popup round position="bottom" v-model="secretProtocolPopupIsShow">
      <div class="secret-protocol-content-popup-container">
        <div
          class="secret-protocol-content"
          v-html="secretProtocolContentHtml"
        ></div>
        <div class="submit-button" @click="secretProtocolPopupIsShow = false">
          好的
        </div>
      </div>
    </van-popup>
    <HeaderBar
      ref="headerBar"
      :step="headerBarData.step"
      :money="headerBarData.money"
      :percentage="headerBarData.percentage"
    />
    <van-swipe
      class="my-swipe"
      :initial-swipe="startIndex"
      @change="updateCurrentSwipeItem"
      ref="swipe"
      :loop="false"
    >
      <van-swipe-item v-for="item in allData" :key="item.propName">
        <div class="tabs-item">
          <SelectPage
            v-if="item.step !== 12"
            @changeActive="insertView"
            :title="item.title"
            :propName="item.propName"
            :step="item.step"
            :options="item.options"
            :percentage="item.percentage"
          />
          <div class="submit-info" v-else>
            <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/loan-info-end-text.png"
              alt=""
            />
            <div class="item">
              <header>
                <img
                  src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/submit-info-icon1.png"
                  alt=""
                />
                <div>官方认证，保护您的隐私安全</div>
                <div class="tip">
                  您的身份证号码仅作真实性验证，我方不会另作他用
                </div>
              </header>
              <main>
                <van-field
                  input-align="right"
                  v-model="paramsList.userName"
                  label="姓名"
                  placeholder="请输入您的真实姓名"
                  type="text"
                  @input="setParamsList('userName', paramsList.userName)"
                />
                <van-field
                  input-align="right"
                  v-model="paramsList.idCard"
                  label="身份证号"
                  placeholder="请输入您的身份证号"
                  type="text"
                  @input="setParamsList('idCard', paramsList.idCard)"
                />
              </main>
            </div>
            <div class="item">
              <header>
                <img
                  src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/submit-info-icon2.png"
                  alt=""
                />
                <div>当前居住地址</div>
                <div class="tip">
                  金融机构不支持跨城市申请，请您选择您的当前居住城市
                </div>
              </header>
              <main @click="showCityPickerPopup = true">
                <div class="city-select">
                  <div>当前居住城市</div>
                  <div v-show="!paramsList.city" style="color: #999999">
                    请选择您当前所在城市
                  </div>
                  <div v-show="paramsList.city">{{ paramsList.city }}</div>
                </div>
              </main>
            </div>
            <div
              class="submit-button"
              v-throttle="2000"
              @click="validateUserInfo"
            >
              提交资料
            </div>
            <div class="protocol-container">
              <i
                v-show="!protocolIsSelected"
                @click="protocolIsSelected = !protocolIsSelected"
              />
              <img
                src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/agree.png"
                alt=""
                v-show="protocolIsSelected"
                @click="protocolIsSelected = !protocolIsSelected"
              />
              <span>我已阅读并同意：</span>
              <em @click="showProtocolPopup(0)">《个人信息授权书》</em>
              <em
                v-show="hasSecretProtocol"
                @click="secretProtocolPopupIsShow = true"
              >
                《隐私协议》
              </em>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <van-popup
      round
      v-model="refuseProtocolPopupIsShow"
      :close-on-click-overlay="false"
    >
      <div class="refuse-protocol-popup-container">
        <h3>流程太繁琐？</h3>
        <p>我们采用自动化AI匹配</p>
        <p>您所填写的资料我们会高度保密</p>
        <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/finish-apply-button.png"
          alt=""
          v-throttle="2000"
          @click="doSubmit"
        />
        <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/to-super-loan-button.png"
          alt=""
          @click="applyCancel"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import SelectPage from "_components/SelectPage";
import HeaderBar from "_components/HeaderBarNew";
import MyLoading from "_components/MyLoading";

import { get_all_city, get_agreement_list } from "_network/submitInfo";
import { validate_user_name_and_id_card } from "_network/userInfo";
import { get_config_by_key } from "_network/config";
import { api_product_user_apply } from "_network/statisticsUv";
import {
  match_product_by_post_user_message,
  apply_big_loan_product,
  save_personal_protocol_image,
} from "_network/product";
import { apply_api_product } from "_network/apiProduct";

import getSearch from "_utils/getSearch";
import personalContent from "_utils/personalContent";

import submitInfoPropName from "_components/submitInfoPropName";
import getNowDate from "_utils/time";
import html2canvas from "html2canvas";
import allData from "./whole-process-steps-data-no-edu-credit";

export default {
  components: {
    SelectPage,
    HeaderBar,
    MyLoading,
  },
  data() {
    return {
      //除去API产品，其余跳下载
      successToDown: process.env.VUE_APP_API_SUCCESS_TO_DOWN,
      // 当前轮播图页面的展示步数，对应0-13
      active: 0,
      allData,
      // 城市选择框弹窗显隐状态
      showCityPickerPopup: false,
      // 再次进入页面时的初始展示步数，对应0-13
      startIndex: 0,
      loadingIsShow: false,
      cityData: [],
      // 对于提交资料页面固定存在的4个单独字段的集合，包括：姓名、身份证号码、城市、是否逾期
      submitInfoPropName,
      // 提交资料页面4个数据的存储对象
      paramsList: {
        userName: "",
        idCard: "",
        city: "",
      },
      // 个人信息授权书勾选状态
      protocolIsSelected: false,
      // 个人信息授权书弹窗显隐状态
      protocolPopupIsShow: false,
      // 协议弹窗下方'好的'按钮是否展示
      okButtonIsShow: true,
      // 多个协议列表时默认展示的协议索引值
      protocolsTitleIndex: 0,
      // 协议名称展示集合
      bigAgreementName: ["个人信息授权书"],
      // 协议展示地址集合
      bigAgreementFile: [],
      // 个人信息授权书内容
      protocolContentHtml: [],
      // 匹配得到的产品
      product: {},
      // 产品匹配成功后3秒的遮罩层显隐
      countDownCoverIsShow: false,
      // 该状态在协议弹框弹出时为true，在协议截图完成时改为false，用来阻止用户滑动协议以及点击提交申请的按钮
      stopSubmit: false,
      // 部分渠道用来展示隐私协议
      hasSecretProtocol: process.env.VUE_APP_LOAN_INFO_HAS_SECRET_PROTOCOL,
      secretProtocolContentHtml: "",
      secretProtocolPopupIsShow: false,
      // 用户点击协议弹框的 我不同意 后弹出的确认弹框
      refuseProtocolPopupIsShow: false,
    };
  },
  computed: {
    headerBarData() {
      return this.allData[this.active];
    },
  },
  watch: {
    active(newActive) {
      if (newActive && newActive !== NaN) {
        localStorage.setItem("active", newActive);
      }
    },
  },
  methods: {
    selectCity(params) {
      this.setParamsList("city", params[1]);
      this.showCityPickerPopup = false;
    },
    insertView(step) {
      const length = Object.keys(this.$getUserInfo()).length + 1;
      this.allData = allData.slice(0, length);
      this.startIndex = step ? step - 1 : localStorage.getItem("active");
      this.$nextTick(() => {
        this.active = step || Number(localStorage.getItem("active")) || 0;
        this.$refs.headerBar.changeMoney();
        this.$refs.headerBar.changePercentage();
        this.$refs.swipe.swipeTo(this.active);
        (document.documentElement || document.body).scrollTop = 0;
      });
    },
    updateCurrentSwipeItem(page) {
      this.active = page;
    },
    async getAllCity() {
      try {
        const { data } = await get_all_city();
        this.cityData = data.map((item) => {
          const children = JSON.parse(JSON.stringify(item.childrenList));
          item.children = children;
          delete item.childrenList;
          return item;
        });
      } catch (e) {}
    },
    //获取个人信息授权书协议
    async getProtocolContentHtml() {
      this.bigAgreementName = ["个人信息授权书"];
      this.bigAgreementFile = [];
      try {
        const { data } = await get_agreement_list();
        data.forEach((protocol) => {
          if (
            process.env.VUE_APP_LOAN_INFO_HAS_SECRET_PROTOCOL &&
            protocol.name === "隐私协议"
          ) {
            this.secretProtocolContentHtml = protocol.content;
            this.secretProtocolContentHtml =
              this.secretProtocolContentHtml.replaceAll("51微卡包", "51微钱包");
          }

          if (protocol.name === "个人信息授权书") {
            this.protocolContentHtml = protocol.content;
            if (process.env.VUE_APP_IS_SPECIAL_PERSONAL) {
              this.protocolContentHtml = personalContent;
            }
            if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                "{productName}",
                process.env.VUE_APP_PROTOCOL_PLATFORM_NAME
              );
            }
            if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                "{productCompanyName}",
                process.env.VUE_APP_PROTOCOL_COMPANY_NAME
              );
            }
            localStorage.setItem("agreementId", protocol.id);
          }
        });
      } catch (e) {}
    },
    // 弹出个人信息授权书操作
    showProtocolPopup(type) {
      // type为0的时候协议仅为展示，按钮只需要有一个即可
      if (type === 0) {
        this.okButtonIsShow = true;
      } else {
        this.okButtonIsShow = false;
      }
      this.protocolPopupIsShow = true;
    },
    // 提交资料页面的方法
    setParamsList(key, value) {
      if (key === "idCard") {
        this.$set(
          this.paramsList,
          "idCard",
          this.paramsList.idCard.slice(0, 18)
        );
      } else {
        this.$set(this.paramsList, key, value);
      }
      const userInfo = this.$getUserInfo();
      userInfo[key] = this.paramsList[key];
      this.$setUserInfo(userInfo);
    },
    async validateUserInfo() {
      if (!this.paramsList.userName) {
        this.$toast({
          type: "fail",
          message: "请先填写您的姓名",
        });
        return;
      }
      if (!this.paramsList.idCard) {
        this.$toast({
          type: "fail",
          message: "请先填写您的身份证号码",
        });
        return;
      }
      if (!this.paramsList.city) {
        this.$toast({
          type: "fail",
          message: "请先选择您当前所在的城市",
        });
        return;
      }
      // 必填项校验完成
      // 校验身份证和姓名是否匹配成功
      try {
        const { data } = await validate_user_name_and_id_card({
          userName: this.paramsList.userName,
          idCard: this.paramsList.idCard,
        });
        if (data) {
          // 该渠道不匹配产品直接跳下载
          if (process.env.VUE_APP_NOT_MATCH_PRODUCT_TO_IN_REVIEW) {
            await this.$router.replace("/inReview");
            return;
          }

          // 该渠道不匹配产品直接跳下载
          if (process.env.VUE_APP_NOT_MATCH_PRODUCT_TO_DOWNLOAD) {
            this.$toDownload(0);
            return;
          }

          // 该渠道不申请直接弹协议
          if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
            this.showProtocolPopup();
            return;
          }

          // 身份证和姓名匹配正确，开始匹配产品
          await this.matchProduct();
        } else {
          this.$toast({
            type: "fail",
            message: "请正确填写姓名和身份证号码",
          });
        }
      } catch (e) {}
    },
    // 匹配产品
    async matchProduct() {
      // 处理匹配时的资料参数
      const userInfo = this.$getUserInfo();

      userInfo.education = "本科及以上";
      userInfo.credit = "3万以上";
      switch (userInfo.loanMoney) {
        case "20万以上":
          userInfo.loanMoney = 300000;
          break;
        case "10-20万":
          userInfo.loanMoney = 200000;
          break;
        case "5-10万":
          userInfo.loanMoney = 100000;
          break;
        case "1-5万":
          userInfo.loanMoney = 50000;
          break;
        case "2000元-1万":
          userInfo.loanMoney = 10000;
          break;
      }
      userInfo.overdue =
        userInfo.overdue === "当前无逾期" ? "无逾期" : userInfo.overdue;

      // 正式提交资料匹配产品
      try {
        this.loadingIsShow = true;
        const { data } = await match_product_by_post_user_message(userInfo);

        // 没有产品直接跳贷超或下载
        if (!data) {
          // 申请机构后跳下载
          if (
            this.successToDown &&
            localStorage.getItem("perceptionSkip") == 2
          ) {
            if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
              this.$toDownload(0);
              return;
            } else if (
              !this.$isIos() &&
              localStorage.getItem("phoneType") == 2
            ) {
              this.$toDownload(0);
              return;
            } else if (localStorage.getItem("phoneType") == 0) {
              this.$toDownload(0);
              return;
            }
          }

          localStorage.setItem("submitSuccess", "1");
          await this.$router.replace("/superLoan");
          return;
        }

        localStorage.setItem("bigLoanProduct", JSON.stringify(data));
        this.product = JSON.parse(localStorage.getItem("bigLoanProduct"));
        if (
          this.paramsList.city === "北京市" &&
          process.env.VUE_APP_SPECIAL_CITY
        ) {
          this.product.productType = 0;
        }
        // 这里获取到的产品如果是极速贷(productType为0)则直接跳转贷超，否则弹出协议并获得截图
        if (this.product.productType === 0) {
          // 该渠道直接跳失败，并且永远无法到达贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
            await this.$router.replace("/fail");
            return;
          }

          // 该渠道直接跳失败，但是成功后的流程是正常的，能够前往贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
            await this.$router.replace("/fail");
            return;
          }

          // 该渠道没有匹配到产品就直接跳下载页面
          if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
            this.$toDownload(0);
            return;
          }

          // 申请机构后跳下载
          if (
            this.successToDown &&
            localStorage.getItem("perceptionSkip") == 2
          ) {
            if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
              this.$toDownload(0);
              return;
            } else if (
              !this.$isIos() &&
              localStorage.getItem("phoneType") == 2
            ) {
              this.$toDownload(0);
              return;
            } else if (localStorage.getItem("phoneType") == 0) {
              this.$toDownload(0);
              return;
            }
          }

          localStorage.setItem("submitSuccess", "1");
          await this.$router.replace("/special");
        } else {
          // 这里是匹配到了大额贷的情况，如果是本地api产品则不会弹窗，直接进件然后前往api中间页
          if (
            this.product.instApiState === 1 &&
            (this.product.localState === 1 || this.product.localState === 2)
          ) {
            // 该渠道匹配到本地api直接跳下载
            if (process.env.VUE_APP_SUPER_LOAN_ONLY_LOCAL_STATE) {
              this.$toDownload(0);
              return;
            }

            // api产品存入本地，在后续中间页需要使用
            localStorage.setItem("apiProduct", JSON.stringify(this.product));
          }

          // 这里是大额贷或者api的h5产品，正常弹出协议即可
          this.loadingIsShow = false;

          // 处理协议
          this.replaceProtocolContent();
        }
      } catch (e) {
        this.loadingIsShow = false;
      }
    },
    // 处理协议，替换协议内容，弹出协议并获得截图
    replaceProtocolContent() {
      let protocolList = [];
      if (
        this.product.xdApiAgreementList &&
        this.product.xdApiAgreementList.length !== 0
      ) {
        protocolList = this.product.xdApiAgreementList;
      }

      // 把protocolList循环分别赋值给bigAgreementNameList和bigAgreementFileList
      this.product.bigAgreementName = protocolList.map(
        (protocol) => protocol.name
      );
      this.product.bigAgreementFile = protocolList.map(
        (protocol) => protocol.url
      );

      // 处理协议弹框顶部的多个协议
      if (this.product.bigAgreementName) {
        // api协议在获取时已经成为数组，因此不用重复调用split方法，其他为本地配置的协议时字段为字符串，因此改为数组
        const bigAgreementNameList = this.product.bigAgreementName;
        const bigAgreementFileList = this.product.bigAgreementFile;
        if (bigAgreementNameList.length === 1) {
          this.bigAgreementName.push(...bigAgreementNameList);
          this.bigAgreementFile.push(...bigAgreementFileList);
        } else {
          bigAgreementNameList.splice(1, 0, "个人信息授权书");
          bigAgreementFileList.splice(1, 0, "");
          this.bigAgreementName = bigAgreementNameList;
          this.bigAgreementFile = bigAgreementFileList;
          this.protocolsTitleIndex = 1;
          this.$nextTick(() => {
            this.protocolsTitleIndex = 1;
          });
        }
      }

      // 获取用户信息填入协议
      const userInfo = this.$getUserInfo();
      const userName = userInfo.userName
        .split("")
        .map((item, index) => {
          if (index === 0) {
            return item;
          } else {
            return "*";
          }
        })
        .join("");

      // 身份证号判空
      userInfo.idCard = userInfo.idCard ? userInfo.idCard : "";

      // 协议内容填充
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{name}",
        userName
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{idCard}",
        userInfo.idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1********\$2")
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{time}",
        getNowDate()
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{institutionName}",
        this.product.name || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{companyName}",
        this.product.companyName || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{address}",
        this.product.address || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{productName}",
        this.product.productName || ""
      );
      this.protocolContentHtml = this.protocolContentHtml.replace(
        "{productCompanyName}",
        this.product.productCompanyName || ""
      );

      // 弹出协议
      this.showProtocolPopup();

      // 截图状态开始时不可以点击提交
      this.stopSubmit = true;

      // 弹出协议之后进行截图，截图需要等弹出动画结束
      setTimeout(() => {
        this.html2canvas();
      }, 300);
    },
    doSubmit() {
      localStorage.setItem("submitSuccess", "1");
      this.countDownCoverIsShow = true;
      setTimeout(() => {
        this.doJump();
      }, 1500);
    },
    // 点击协议内容的同意并申请后进行的产品申请及跳转
    async doJump() {
      // 该渠道不申请任何产品，直接跳转审核中页面
      if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
        await this.$router.replace("/inReview");
        return;
      }

      // 根据返回产品的jumpState字段判断后续是否跳转贷超(0跳转 1不跳转)
      localStorage.setItem("quickLoanToSuperLoan", this.product.jumpState);

      // 截图保存给后端
      const base64Img = localStorage.getItem("base64Img");
      const file = base64Img ? this.base64ToFile(base64Img, "image") : "";
      save_personal_protocol_image({
        data: file,
        ...this.product,
      });

      try {
        // 大额贷申请产品，api进件
        const { data } = await apply_big_loan_product({
          productId: this.product.id,
          instApiState: this.product.instApiState,
          bigLoanType: this.product.isKlhdProduct,
        });

        // api产品
        if (this.product.instApiState === 1 && data.result) {
          // api产品进件后要埋点
          api_product_user_apply({ channelSign: getSearch().channelSign });

          // 本地api产品，直接推送，然后跳转中间页即可
          if (this.product.localState === 1) {
            // 推送api产品
            await apply_api_product(this.product.id);

            // 跳转中间页
            await this.$router.replace("/apiProductMiddlePage");
            return;
          }

          // 分润Api，直接跳转中间页
          if (this.product.localState === 2) {
            await this.$router.replace("/apiProductMiddlePage");
            return;
          }

          // h5Api直接跳链接
          if (this.product.localState === 0) {
            // 特殊渠道api的h5也直接跳下载
            if (process.env.VUE_APP_H5_API_PRODUCT_TO_DOWNLOAD) {
              this.$toDownload(0);
              return;
            }

            // 跳链接
            location.replace(data.productDto.h5Url);
            return;
          }
        }

        // 这里是whole-process-51渠道的特殊配置
        if (process.env.VUE_APP_BIG_LOAN_PRODUCT_AFTER_APPLY_TO_DOWNLOAD) {
          this.$toDownload(0);
          return;
        }

        if (!data.result) {
          // 申请机构后跳下载
          if (
            this.successToDown &&
            localStorage.getItem("perceptionSkip") == 2
          ) {
            if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
              this.$toDownload(0);
              return;
            } else if (
              !this.$isIos() &&
              localStorage.getItem("phoneType") == 2
            ) {
              this.$toDownload(0);
              return;
            } else if (localStorage.getItem("phoneType") == 0) {
              this.$toDownload(0);
              return;
            }
          }
          this.$toast.fail("该产品已下架");

          // 进件失败跳贷超
          await this.$router.replace("/superLoan");
          return;
        }
        // 存储字段，大额贷申请成功
        localStorage.setItem("bigLoanProductApplySuccess", "1");
        await this.$router.replace("/examining");
      } catch (e) {
        this.countDownCoverIsShow = false;
      }

      this.countDownCoverIsShow = false;
    },
    // 用户点击了协议弹出框下方的 我不同意 按钮
    refuseProtocol() {
      // 首先关闭协议弹窗
      this.protocolPopupIsShow = false;
      // 等关闭动画300ms结束，弹出再次询问的弹框
      setTimeout(() => {
        this.refuseProtocolPopupIsShow = true;
      }, 300);
    },
    // 用户取消申请
    applyCancel() {
      // 该渠道不会申请任何产品，取消后关闭弹框，停留在本页面
      if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
        this.refuseProtocolPopupIsShow = false;
        return;
      }

      // 该渠道直接跳失败，并且永远无法到达贷超
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
        this.$router.replace("/fail");
        return;
      }

      // 该渠道直接跳失败，但是成功后的流程是正常的，能够前往贷超
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
        this.$router.replace("/fail");
        return;
      }

      // 该渠道没有匹配到产品就直接跳下载页面
      if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
        this.$toDownload(0);
        return;
      }

      // 申请机构后跳下载
      if (this.successToDown && localStorage.getItem("perceptionSkip") == 2) {
        if (this.$isIos() && localStorage.getItem("phoneType") == 1) {
          this.$toDownload(0);
          return;
        } else if (!this.$isIos() && localStorage.getItem("phoneType") == 2) {
          this.$toDownload(0);
          return;
        } else if (localStorage.getItem("phoneType") == 0) {
          this.$toDownload(0);
          return;
        }
      }

      localStorage.setItem("submitSuccess", "1");
      this.$router.replace("/superLoan");
    },
    // 存储用户信息
    setUserInfo() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.submitInfoPropName.forEach((propName) => {
        this.$set(
          this.paramsList,
          propName,
          (userInfo && userInfo[propName]) || ""
        );
      });
    },
    // 需要获取定位
    async getIpIndex() {
      try {
        const { data } = await axios.get(
          "https://restapi.amap.com/v3/ip?key=ddf65729766280539c465816e0a6b4b3"
        );
        const result = data.city && data.city.length !== 0 ? data.city : "";
        this.setParamsList("city", result.indexOf("省") !== -1 ? "" : result);
      } catch (e) {
        console.log(e.message);
      }
    },
    getIndex() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (res) => {
            try {
              const { longitude, latitude } = res.coords;
              const url = `https://restapi.amap.com/v3/geocode/regeo?key=ddf65729766280539c465816e0a6b4b3&location=${longitude},${latitude}`;
              const { data } = await axios.get(url);
              const result =
                (data.regeocode.addressComponent.city.length &&
                  data.regeocode.addressComponent.city) ||
                data.regeocode.addressComponent.province;
              this.setParamsList(
                "city",
                result.indexOf("省") !== -1 ? "" : result
              );
              if (result === "") {
                await this.getIpIndex();
              }
            } catch (e) {
              await this.getIpIndex();
            }
          },
          (e) => {
            if (e.message !== "User denied Geolocation") {
              this.getIpIndex();
            } else {
              console.log("用户未授权");
            }
          }
        );
      } else {
        this.getIpIndex();
      }
    },
    // 截图
    getShareImgBase64() {
      return new Promise((resolve) => {
        setTimeout(() => {
          html2canvas(document.querySelector("#loan-info"), {
            // useCORS: true, // 【重要】开启跨域配置
            scale: 1,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            resolve(imgData);
          });
        }, 800);
      });
    },
    html2canvas() {
      this.$nextTick(() => {
        this.getShareImgBase64().then((res) => {
          localStorage.setItem("base64Img", res);
          // 截图完成可以提交资料
          this.stopSubmit = false;
        });
      });
    },
    // 转换截图为file
    base64ToFile(data, fileName) {
      const dataArray = data.split(",");
      const byteString = atob(dataArray[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const utf8Array = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        utf8Array[i] = byteString.charCodeAt(i);
      }
      const formData = new FormData();
      let fileOfBlob = new File([utf8Array], fileName + ".jpg", options); //返回文件流
      formData.append("image", fileOfBlob);
      return formData;
    },
    // 获取配置项
    async getConfig() {
      // 该配置项为大额贷申请成功页面自动跳转的倒计时，单位s
      get_config_by_key(
        "big_loan_apply_success_skip_count_down_config_key"
      ).then(({ code, data }) => {
        if (code === 200) {
          localStorage.setItem("examiningToSuperLoanCountDown", data);
        }
      });
    },
  },
  created() {
    this.$setHeaderMoney("5000")
    this.getAllCity();
    this.insertView();

    // 渠道不获取定位
    if (!process.env.VUE_APP_NOT_GET_INDEX) {
      this.getIndex();
    }

    this.setUserInfo();
    this.getProtocolContentHtml();
    this.getConfig();
  },
};
</script>

<style lang="less" scoped>
#loan-info {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;

  ::v-deep .van-picker__confirm,
  ::v-deep .van-picker__cancel {
    padding: 0 32px;
  }

  ::v-deep .van-picker__toolbar {
    height: 64px;
  }

  .content-hidden {
    overflow-y: hidden !important;
  }

  ::v-deep .van-swipe__indicators {
    display: none;
  }

  .tabs-item,
  ::v-deep .van-swipe-item {
    min-height: calc(100vh - 65px);
  }

  ::v-deep .van-tabs__line {
    background: transparent;
  }

  .count-down-cover {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    width: 100vw;
    height: 100vh;
    background: #fff;

    img {
      width: 4.267rem;
      margin: 3.44rem auto 0;
    }
  }

  .protocol-popup-container {
    overflow: hidden;
    width: 100vw;
    background: #ffffff;

    .protocol-content-container {
      width: 100%;
      height: 322px;
      padding: 0 29px;
      margin-bottom: 20px;
      font-size: 14px;

      ::v-deep .van-tab--active .van-tab__text {
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }

      ::v-deep .van-tab {
        padding: 0 10px;
      }

      .protocol-content {
        overflow-y: auto;
        padding-top: 10px;
      }

      .protocol-content-no-scroll {
        overflow: hidden;
      }

      .protocol-content,
      iframe {
        width: 100%;
        height: 278px;
      }
    }

    .protocol-do-agree-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin: 0 auto 0.4rem;
      background: #325ef3;
      border-radius: 0.667rem;
      font-size: 0.427rem;
      font-weight: 500;
      color: #ffffff;
    }

    .protocol-refuse-button {
      padding-bottom: 0.4rem;
      font-size: 0.373rem;
      text-align: center;
      color: #999999;
    }
  }

  .secret-protocol-content-popup-container {
    padding: 0.427rem;

    .secret-protocol-content {
      overflow-y: auto;
      width: 100%;
      height: 8.7838rem;
    }

    .submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.333rem;
      margin-top: 0.427rem;
      background: #325ef3;
      border-radius: 0.667rem;
      font-size: 0.48rem;
      color: #ffffff;
    }
  }

  .submit-info {
    padding: 0 0.5135rem 0 0.5405rem;

    & > img {
      margin-top: 0.133rem;
    }

    .item {
      margin: 0.747rem 0 0;

      &:last-of-type {
        margin-top: 0.853rem;
      }

      header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 500;
        color: #333333;

        img {
          width: 0.5405rem;
        }

        .tip {
          padding: 0.1081rem 0 0 0.5405rem;
          font-size: 11px;
          font-weight: 400;
          color: #999999;
        }
      }

      main {
        margin-top: 0.3514rem;
        background: #f8f8f8;
        border-radius: 0.2162rem;
        border: 1px solid #f3f3f3;

        ::v-deep .van-field {
          display: flex;
          align-items: center;
          height: 1.3514rem;
          padding: 0 0.4324rem;
          background: transparent;
        }

        ::v-deep span,
        ::v-deep .van-field__control:disabled {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }

        ::v-deep input::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }

        .city-select {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.4324rem;
          height: 1.3514rem;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 1.3514rem;
      margin: 2.2162rem auto 0.4rem;
      background: #325ef3;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 0.6486rem;
    }

    .protocol-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.32rem;
      color: #5a5a5a;

      i,
      img {
        width: 0.267rem;
        height: 0.267rem;
        margin-right: 0.107rem;
      }

      i {
        border: 1px solid #2c92ff;
        border-radius: 50%;
      }

      em {
        color: #7c8395;
      }
    }
  }

  .refuse-protocol-popup-container {
    width: 9.467rem;
    padding: 0.533rem 0.747rem;
    background: #ffffff;
    text-align: center;

    h3 {
      margin-bottom: 0.373rem;
      font-weight: 600;
      font-size: 0.533rem;
      color: #333333;
    }

    p {
      font-size: 0.48rem;
      color: #333333;
      line-height: 0.667rem;
    }

    img:first-of-type {
      margin: 0.667rem 0 0.293rem;
    }
  }
}
</style>
