const allData = [
  {
    money: 5000,
    step: 1,
    propName: "loanMoney",
    percentage: 57,
    title: "您想借多少？",
    options: ["20万以上", "10-20万", "5-10万", "1-5万", "2000元-1万"],
  },
  {
    money: 15000,
    step: 2,
    propName: "occupation",
    percentage: 61,
    title: "您的职业是什么？",
    options: ["个体户/企业主", "上班族", "自由职业"],
  },
  {
    money: 20000,
    step: 3,
    propName: "socialSecurity",
    percentage: 65,
    title: "您在本地社保缴纳多长时间？",
    options: ["6个月以上", "6个月以下", "无本地社保"],
  },
  {
    money: 25000,
    step: 4,
    propName: "accumulationFund",
    percentage: 69,
    title: "您在本地公积金缴纳多长时间？",
    options: ["6个月以上", "6个月以下", "无本地公积金"],
  },
  {
    money: 30000,
    step: 5,
    propName: "insurance",
    percentage: 73,
    title: "您的投保信息？",
    options: ["投保两年以上", "投保两年以下", "无"],
  },
  {
    money: 35000,
    step: 6,
    propName: "car",
    percentage: 77,
    title: "您名下是否有车产？",
    options: ["有且接受抵押", "有", "无"],
  },
  {
    money: 40000,
    step: 7,
    propName: "house",
    percentage: 81,
    title: "您名下是否有房产？",
    options: ["有且接受抵押", "有", "无"],
  },
  {
    money: 45000,
    step: 8,
    propName: "sesame",
    percentage: 85,
    title: "请选择您的芝麻分范围",
    options: ["700分以上", "650-699分", "600-649分", "600分以下"],
  },
  {
    money: 55000,
    step: 9,
    propName: "huaBei",
    percentage: 89,
    title: "您的花呗额度是多少？",
    options: ["10000以上", "5000-10000", "0-5000", "无"],
  },
  {
    money: 60000,
    step: 10,
    propName: "whiteNote",
    percentage: 93,
    title: "您的京东白条额度是多少？",
    options: ["5000以上", "2000-5000", "0-2000", "无"],
  },
  {
    money: 65000,
    step: 11,
    propName: "overdue",
    percentage: 97,
    title: "您的逾期情况？",
    options: ["当前无逾期", "当前有逾期"],
  },
  {
    money: 70000,
    step: 12,
    percentage: 100,
  },
];

export default allData;
