<template>
  <van-overlay :show="show" style="display: flex; align-items: center;justify-content: center;z-index: 1000000; width: 100vw;height: 100vh;">
    <van-loading size="24px"  color="#1989fa" vertical></van-loading>
  </van-overlay>
</template>

<script>
export default {
  props: {
    show: Boolean
  }
}
</script>
