import request from '_utils/request'

// 领取额度按钮埋点
const examining_click_submit_button = () => {
  return request({
    url: '/api/buried/addH5MoreLoan',
    method: 'GET'
  })
}

// 老用户和新用户在提交资料后匹配到了api产品，进件时调用接口
const api_product_user_apply = (data) => {
  return request({
    url: '/api/buried/apiApplyClick',
    method: 'POST',
    data
  })
}

export {
  examining_click_submit_button,
  api_product_user_apply
}
